<template>
  <div class="player-icon" :class="{'player-icon--playing': player.playing}">
    <icon v-if="!player.playing" title="Play" name="Play" class="player-icon__icon" fill />
    <icon v-else title="Pause" name="Pause" class="player-icon__icon" fill />
  </div>
</template>

<script setup lang="ts">
const player = usePlayerState()
</script>

<style lang="scss" scoped>
@use "~/assets/sass/tools";

.player-icon {
  &__icon {
    display: block;
    width: 100%;
    height: 100%;
    color: var(--color-white);
  }

  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}
</style>
